import React, {createRef, CSSProperties, useContext, useEffect, useMemo, useState} from "react";
import { Link } from "gatsby"
import * as styles from "./menus.module.scss";
import {Header} from "../core/Header";
import {AnchorContext} from "../../atoms/anchor/AnchorProvider";
import {cssWithPrefix} from "../../utils/prefixer";
import {SpHorizontalMenu} from "../../atoms/menu/SpHorizontalMenu";

const links:ILink[] = [
  {
    id: "top",
    label: "Top",
    href: "/careers/diversity",
  },
  {
    id: "support",
    label: "Support",
    href: "/careers/diversity/#support",
  },
  {
    id: "faq",
    label: "FAQ",
    href: "/careers/diversity/#faq",
  },
  {
    id: "entry",
    label: "How to Entry",
    href: "/careers/diversity/#entry",
  },
  {
    id: "contact",
    label: "Contact",
    href: "/contact?type=diversity",
  },
];

export const DiversityHeader = () => {
  const [currentStyle, setCurrentStyle] = useState({});
  const { setAnchors, currentAnchorIndex } = useContext(AnchorContext);
  const menuParentRef = createRef<HTMLDivElement>();
  const refs = useMemo(
      () => Array.from({ length: links.length }).map(() => createRef<any>()),
      [links.length]
  );
  useEffect(() => {
    setAnchors(links.map(link => link.id));
  }, []);
  const currentMenu = refs[currentAnchorIndex]?.current as HTMLElement;
  useEffect(() => {
    if (currentMenu) {
      const parentRect = (menuParentRef.current as HTMLElement)?.getBoundingClientRect();
      const rect = currentMenu.getBoundingClientRect();
      const css:CSSProperties = {};
      css.width = `${rect.width}px`;
      cssWithPrefix(css, 'transform', `translate3d(${rect.left - parentRect?.left}px, 44px, 0)`);
      setCurrentStyle(css);
    }
  }, [currentAnchorIndex, currentMenu]);
  return <Header>
    <menu className={styles.menuBackground}>
      <div className={styles.menuDiversityInner} ref={menuParentRef}>
        <label className={styles.diversityTitle}>障がい者採用</label>
        <div className="flex-1"/>
        <ul className={styles.menuListDiversity}>
          {
            links.map(({href, label}, i) => <li key={`diversity-menu-${i}`}>
              <Link to={href} ref={refs[i]}>{label}</Link>
            </li>)
          }
        </ul>
        {
          currentMenu && <span className={`${styles.indicator} ${styles.diversity}`} style={currentStyle} />
        }
      </div>
      <SpHorizontalMenu index={currentAnchorIndex} className={styles.menuDiversitySp}>
        {
          links.map(({href, label}, i) => <li className={styles.menuDiversitySpItem} key={`diversity-sp-menu-${i}`}>
            <Link to={href}>{label}</Link>
          </li>)
        }
      </SpHorizontalMenu>
    </menu>
  </Header>;
};