import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { DiversityHeaderLayout } from "../../organisms/careers/DiversityLayout";
import * as styles from "../../styles/careers/diversity.module.scss";
import { Link } from "gatsby";
import * as commonStyles from "../../styles/common.module.scss";
import Scroll, { scrollImageColorTypes } from "../../atoms/newScroll";
import { LinkMargin } from "../../atoms/LinkMargin";
import DiversityCover from "../../images/career_pwd/adobestock_351815619_re.jpg";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/career_mid/career_mid_head.jpg";
import Drawer from "../../atoms/drawer";

export default ({ location: { pathname } }) => (
  <DiversityHeaderLayout>
    <SEO
      pathname={pathname}
      title="障がい者採用 | Diversity"
      description="スプリーブホールディングス株式会社の障がい者採用情報はこちらからご確認いただけます。社会、顧客、従業員に対して、「この上ない最高」を提供する会社を共に目指し、人と人の繋がりを強めて力強く事業を推進していく仲間を募集しています。"
      image={seoImage}
    />
    <div className={commonStyles.coverContainer}>
      <img
        className={styles.diversityCover}
        src={DiversityCover}
        alt="障がい者採用"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
    <a id="top" />
    <div className={styles.diversityTitle}>
      <h2>Hire persons with disabilities</h2>
      <h3>障がい者採用</h3>
      <Scroll
        type={scrollImageColorTypes.BLACK}
        className={styles.CareerDiversity__scroll}
      />
    </div>
    <div className={styles.about}>
      <h2>
        障がいを個性と捉え、
        <br />
        多様性を自然に 受け入れる社風です
      </h2>
      <p>
        弊社では障がいを個性と認識する事で、障がいをお持ちの方を特別扱いする事なく、全ての社員が誇り持って仕事に取り組めるような環境です。現在も障がいに対する配慮をしつつ、様々な分野で障がいのある社員が活躍しています。
        <br />
        弊社では障がいの有無関係なく能力をみて評価をしており、仲間を大事に思い行動する事。日々ご自身の成長のために努力する姿勢、業務を行った結果等、評価人事制度を元にやキャリア形成、役職への抜擢を行っています。
        <br />
        <br />
        また、ご障がいをお持ちの方の適性を最大限に活かし、仕事を通じて誇りをもって自立した生活を送る事をご支援するために、グループ会社であるAsFine株式会社が運営する、就労継続支援A型事業所にて、就労支援のサポートもしております。こちらのA型事業所で業務スキル、実務経験を身に着けて、一般企業様への就労支援はもちろん、能力適正があり、尚且つスキルが十分でご本人がご希望される場合、Suprieveでの採用も行っております。
      </p>
    </div>

    <div className={styles.diversityImg}>
      <StaticImage src="../../images/career_pwd/yp0_1730.jpg" alt="" />
    </div>

    <div className={styles.support}>
      <StaticImage
        className={styles.supportImg}
        src="../../images/career_pwd/_kpo0935.jpg"
        alt=""
      />
      <div className={styles.supportContainer}>
        <h2> </h2>
        <a id="support" />
        <div className={styles.marginWrap}>
          <LinkMargin />
        </div>
        <h2>障がいのある社員へのサポート</h2>
        <h3>状況の変化に応じて、残業や出張、業務量を調整</h3>
        <p>
          本人の状況を見ながら、残業や出張などの業務を軽減しています。状況に変化があった時には、相談員や医療職と相談しながら適宜対応方法を決定しています。
        </p>
        <h3>
          通院時の業務調整や外出時の安全確保など
          <br />
          ソフト面の配慮を実施
        </h3>
        <p>
          定期的な通院が必要な社員に対して勤務時間の配慮、業務調整を実施。その他外出時や出張時には同行者と共に移動するなど安全確保を行っています。
        </p>
        <h3>時差出勤や休暇取得、各種制度で通院をサポート</h3>
        <p>
          通院の際には、フレックスタイム制度、年次有給休暇制度の範囲内で可能な限りの配慮を実施。業務量や業務時間の調整を行っています。
        </p>
        <h3>
          業務に取り組むうえで混乱しないよう
          <br />
          優先順位や作業手順を見える化
        </h3>
        <p>
          計画立てて業務を進めることが苦手な社員に向けて、業務手順を示したマニュアルを作成。優先順位や目標を明確にし、指示を一つずつ出すなど工夫しています。
        </p>
      </div>
    </div>

    <div className={styles.performanceTitle}>
      <h2>就労実績　※手帳区分</h2>
    </div>

    <div className={styles.performanceContainer}>
      <h2>身体障害者手帳</h2>
      <h3>上肢・体幹・心臓・免疫機能</h3>
    </div>
    <div className={styles.performanceContainer}>
      <h2>精神障害者保健福祉手帳</h2>
      <h3>うつ・双極・統合失調症・発達</h3>
    </div>
    <div className={styles.performanceContainer}>
      <h2>療育手帳</h2>
      <h3>知的</h3>
    </div>

    <div className={styles.faq}>
      <a id="faq" />
      <LinkMargin />
      <h2>
        FAQ<span>よくある質問</span>
      </h2>
      <h5>皆さんから多く寄せられたご質問について、回答を掲載しています。</h5>

      <div className={styles.faqTab}>
        <div className={styles.tab1}>
          <Link to="#saiyou">
            <h3>採用・選考について</h3>
          </Link>
        </div>
        <div className={styles.tab1}>
          <Link to="#shigoto">
            <h3>仕事について</h3>
          </Link>
        </div>
      </div>
    </div>

    <div className={styles.acd}>
      <a id="saiyou" />
      <LinkMargin />
      <div className={styles.linkMargin} />
      <h2>採用・選考について</h2>

      <Drawer
        items={[
          {
            mainText: "Q.応募受付が出来ない障害はありますか？",
            subTexts: [
              "A.障害による応募制限は設けていません。原則として、弊社の就業規則の範囲内で、勤務できる方が対象です。",
            ],
          },
          {
            mainText:
              "Q.新卒採用、中途採用、障がい者採用との併願は可能ですか？",
            subTexts: [
              "A.上記のうち、いずれかの応募となります。併願は出来ませんのでご了承ください。",
            ],
          },
          {
            mainText: "Q.応募方法について教えてください。",
            subTexts: [
              "A.応募書類（履歴書・職務経歴書・障がい者手帳のコピー）をメールに添付し〇〇〇〇宛にお送り下さい。",
              "書類選考の結果を１～２週間以内に追ってご連絡いたします。",
            ],
          },
        ]}
        className={styles.drawer}
      />
    </div>

    <div className={styles.acd}>
      <a id="shigoto" />
      <LinkMargin />
      <h2>仕事について</h2>

      <Drawer
        items={[
          {
            mainText:
              "Q.一般枠採用と障害者要枠との採用では、具体的にどんな違いがありますか？",
            subTexts: [
              "A.基本的に同じです。障がいについての配慮が必要な点を詳しくお聞きし、同じフィールドで活躍していただきます。",
            ],
          },
          {
            mainText: "Q.応募のできない職種はありますか？",
            subTexts: ["A.ありません。どの職種にも応募可能です。"],
          },
          {
            mainText: "Q.配属先は希望できますか？",
            subTexts: [
              "A.採用面接以降、ご本人の希望や障がい内容をうかがった上、適正を含めて総合的に判断して職場や業務内容を判断します。具体的なご希望がある場合は、選考試験の際にお知らせください。",
            ],
          },
          {
            mainText: "Q.障がいを持つ社員に対してのフォローはありますか？",
            subTexts: [
              "A.障がいの内容によって必要な配慮事項は変わりますので、選考試験の際に具体的な障がい状況や必要な配慮事項を教えてください。個別に、対応が可能かを判断させていただきます。",
            ],
          },
        ]}
        className={styles.drawer}
      />
    </div>

    <div className={styles.contactBtn}>
      <p>
        その他のご質問に関してはコンタクトページよりメールにてお問い合わせください。
      </p>
      <div className={styles.viewAll}>
        <Link to="/contact?type=diversity">Contact</Link>
      </div>
    </div>

    <div className={styles.arrow}>
      <div className={styles.triangle}></div>
    </div>

    <a id="entry" />
    <LinkMargin />
    <div className={styles.entry}>
      <h2>How to Entry</h2>
      <h3>エントリー方法</h3>
      <p>
        コンタクトフォームより応募書類（履歴書・職務経歴書・障がい者手帳のコピー）を添付してお送り下さい。
        <br />
        書類選考の結果を１～２週間以内に追ってご連絡いたします。
      </p>
      <div className={styles.viewAll}>
        <Link to="/contact?type=diversity">Contact</Link>
      </div>
    </div>
  </DiversityHeaderLayout>
);
